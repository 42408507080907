import { all, fork, takeLatest, put, call } from "redux-saga/effects";
import { NOTICE_DEL_FAILURE, NOTICE_DEL_REQUEST, NOTICE_DEL_SUCCESS, NOTICE_DETAIL_FAILURE, NOTICE_DETAIL_REQUEST, NOTICE_DETAIL_SUCCESS, NOTICE_LIST_FAILURE, NOTICE_LIST_REQUEST, NOTICE_LIST_SUCCESS, NOTICE_MOD_FAILURE, NOTICE_MOD_REQUEST, NOTICE_MOD_SUCCESS, NOTICE_REG_FAILURE, NOTICE_REG_REQUEST, NOTICE_REG_SUCCESS, NOTICE_USE_YN_MOD_FAILURE, NOTICE_USE_YN_MOD_REQUEST, NOTICE_USE_YN_MOD_SUCCESS } from "../reducers/notice";
import NoticeService from "../service/NoticeService";


function* noticeList(action) {
    console.log('saga 호출');
    try{
        const result = yield call(NoticeService.noticeList, action.data);
        console.log('saga / noticeList : ' + result);
        yield put({
            type : NOTICE_LIST_SUCCESS,
            data : result.data,
        });

    } catch( error ) {
        yield put({
            type : NOTICE_LIST_FAILURE,
            error: error.response.data
        });
    }
}

function* noticeUseYnMod(action) {
    try{
        const result = yield call(NoticeService.noticeUseYnMod, action.data);
        console.log('saga / noticeUseYnMod : ' + result);
        yield put({
            type : NOTICE_USE_YN_MOD_SUCCESS,
            data : result.data,
        });
    } catch( error ){
        yield put ({
            type : NOTICE_USE_YN_MOD_FAILURE,
            error: error.response.data
        });
    }
}

function* noticeDel(action){
    try{
        const result = yield call(NoticeService.noticeDel, action.data);
        console.log('saga / noticeDel : ' + result);
        yield put({
            type : NOTICE_DEL_SUCCESS,
            data : result.data,
        });
    } catch( error ){
        yield put ({
            type : NOTICE_DEL_FAILURE,
            error: error.response.data
        });
    }
}

function* noticeReg(action) {
    try{
        const result = yield call(NoticeService.noticeReg, action.data);
        console.log('saga / noticeReg : ' + result);
        yield put({
            type : NOTICE_REG_SUCCESS,
            data : result.data,
        });
    } catch( error ){
        yield put ({
            type : NOTICE_REG_FAILURE,
            error: error.response.data
        });
    }
}

function* noticeMod(action) {
    try{
        const result = yield call(NoticeService.noticeMod, action.data);
        console.log('saga / noticeMod : ' + result);
        yield put({
            type : NOTICE_MOD_SUCCESS,
            data : result.data,
        });
    } catch( error ){
        yield put ({
            type : NOTICE_MOD_FAILURE,
            error: error.response.data
        });
    }
}

function* noticeDetail(action) {
    try{
        const result = yield call(NoticeService.noticeDetail, action.data);
        console.log('saga / noticeDetail : ' + result);
        yield put({
            type : NOTICE_DETAIL_SUCCESS,
            data : result.data,
        });

    } catch( error ) {
        yield put({
            type : NOTICE_DETAIL_FAILURE,
            error: error.response.data
        });
    }
}
// 이벤트 리스너 같은 역할을 한다.
function* watchNotice() {
    console.log('saga / watchNotice');
    yield takeLatest(NOTICE_LIST_REQUEST, noticeList);
    yield takeLatest(NOTICE_USE_YN_MOD_REQUEST, noticeUseYnMod);
    yield takeLatest(NOTICE_DEL_REQUEST, noticeDel);
    yield takeLatest(NOTICE_REG_REQUEST, noticeReg);
    yield takeLatest(NOTICE_MOD_REQUEST, noticeMod);
    yield takeLatest(NOTICE_DETAIL_REQUEST, noticeDetail);
}

export default function* noticeSaga(){
    yield all([
        fork(watchNotice),
    ])
}