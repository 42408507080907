import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { noticeDetailRequestAction } from "../reducers/notice";
import TokenService from "../service/TokenService";
import NavBarBottomComp from "./NavBarBottomComp";
import NavBarComp from "./NavBarComp";

const NoticeDetail = () => {
    const { seq } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    const error = useSelector(
        (state) => state.notice.noticeError,
    );

    const success = useSelector(
        (state) => state.notice.noticeDone,
    );

    const data = useSelector(
        (state) => state.notice.noticeData,
    );

    useEffect(() => {
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(noticeDetailRequestAction(seq));
        });
    },[]);
    
    useEffect(()=>{
        if(data !== null){
            setTitle(data.title);
            setContent(data.content);
        }
    }, [data]);

    return (
        <>
            <NavBarComp />
            <div style={{"paddingTop":"70px", "marginBottom":"50px"}}>
                <div style={{"fontSize":"20px", "fontWeight":"bold","padding":"5px"}}>공지사항 상세</div>
                <Table striped bordered hover size="sm">
                    <tbody>
                        <tr>
                            <td style={{"width":"15%"}}>제목</td>
                            <td>{title}</td>
                        </tr>

                        <tr style={{"height":"300px"}}>
                            <td style={{"width":"15%"}}>내용</td>
                            <td>{content}</td>
                        </tr>

                    </tbody>
                </Table>
                <div style={{"float":"right", "padding":"5px"}}>
                <Button variant="secondary" onClick={()=> {
                    navigate("/notice/list");
                }}>목록으로</Button>
                </div>
            </div>
            <NavBarBottomComp />
        </>
    )
}

export default NoticeDetail;