import { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { noticeListRequestAction , noticeUseYnModRequestAction, noticeDelRequestAction, noticeInitRequestAction} from "../../reducers/notice";
import FcmService from "../../service/FcmService";
import TokenService from "../../service/TokenService";
import NavBarComp from "../NavBarComp";

const ManageNoticeList = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const error = useSelector(
        (state) => state.notice.noticeError,
    );

    const success = useSelector(
        (state) => state.notice.noticeDone,
    );

    const data = useSelector(
        (state) => state.notice.noticeData,
    );

    const modError = useSelector(
        (state) => state.notice.noticeActError,
    );

    const modSuccess = useSelector(
        (state) => state.notice.noticeActDone,
    );

    const modData = useSelector(
        (state) => state.notice.noticeActData,
    );
    
    useEffect(() => {
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(noticeListRequestAction({"useYn":""}));
        });
    },[]);

    useEffect(()=>{
        if(modError === null)return;
        alert('에러가 발생하였습니다.');
    }, [modError]);
    
    useEffect(()=>{
        if(error === null)return;
        alert('목록 조회 중 에러가 발생 하였습니다.');
    }, [error]);
    
    useEffect(()=>{
        if(modData){
            if(modData.code === "00"){
                dispatch(noticeInitRequestAction());
                dispatch(noticeListRequestAction());
            }
        }
    }, [modData]);

    const onClickMsg = (seq, title, content, useYn) => {
        if(useYn === "N"){
            alert("비 노출된 공지사항은 전송할 수 없습니다.");
            return false;
        }
        if (window.confirm("메세지를 전송 하시겠습니까?")) {
            TokenService.tokenIss().then(res => {
                if(res === ""){
                    alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                    navigate("/signin");
                }
            });

            FcmService.sendFcmMsg({"val":seq, "user_point":"", "title":title, "content":content, "type":"notice"}).then(res => {
                if(res === "ok"){
                    alert("메세지 전송을 완료 하였습니다.");
                }
            });
        }
    };

    const onClickDel = (seq) => {
        if (window.confirm("삭제 하시겠습니까?")) {
            TokenService.tokenIss().then(res => {
                if(res === ""){
                    alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                    navigate("/signin");
                }
                dispatch(noticeDelRequestAction({seq:seq}));
            });
        }
    };

    const onClickRegPage = () => {
        navigate("/manage/notice/reg");
    }
    
    const onChaneHandle = (e, seq) => {
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(noticeUseYnModRequestAction({useYn:e.target.value, seq:seq}));
        });
    }

    const onclickDetail = (seq) => {
        navigate("/manage/notice/detail/"+seq);
    }

    return(
        <>
            <NavBarComp />
            <div style={{"paddingTop":"70px"}}>
                <div style={{"width":"100%","textAlign":"right", "paddingRight":"10px"}}>
                    <Button variant="outline-primary" onClick={onClickRegPage}>등록</Button>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>제목</th>
                            <th>노출여부</th>
                            <th>메세지전송</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(data)?
                            data.map((item, i) => {
                                return (
                                    <tr key={ item.seq }>
                                        <td>{ i+1 }</td>
                                        <td onClick={()=> onclickDetail(item.seq)} style={{"cursor":"pointer","fontWeight":"bold"}}>{ item.title }</td>
                                        <td>
                                            <Form.Select defaultValue={item.useYn} onChange={(e) => onChaneHandle(e, item.seq)} style={{"width":"100px", "display":"inline"}}>
                                                <option>Y</option>
                                                <option>N</option>
                                            </Form.Select>
                                        </td>
                                        <td>
                                            <Button variant="primary" onClick={() => onClickMsg(item.seq, item.title, item.content, item.useYn)}>전송</Button>
                                        </td>
                                        <td>
                                            <Button variant="danger" onClick={() => onClickDel(item.seq)}>삭제</Button>
                                        </td>
                                    </tr>
                                )
                            })
                            :null
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default ManageNoticeList;