import { all, fork, takeLatest, put, call } from "redux-saga/effects";
import {LOG_IN_REQUEST,LOG_IN_SUCCESS, LOG_IN_FAILURE, SIGN_UP_REQUEST, SIGN_UP_SUCCESS, SIGN_UP_FAILURE, USER_LIST_SUCCESS, USER_LIST_FAILURE, USER_LIST_REQUEST, USER_DEL_REQUEST, USER_DEL_SUCCESS, USER_DEL_FAILURE, USER_STATE_MOD_FAILURE, USER_STATE_MOD_SUCCESS, USER_AUTH_MOD_SUCCESS, USER_AUTH_MOD_FAILURE, USER_STATE_MOD_REQUEST, USER_AUTH_MOD_REQUEST, USER_CHECK_AUTH_SUCCESS, USER_CHECK_AUTH_FAILURE, USER_CHECK_AUTH_REQUEST, USER_INIT_STATE, SIGN_OUT_REQUEST, SIGN_OUT_FAILURE, SIGN_OUT_SUCCESS, USER_PWD_INIT_REQUEST, USER_PWD_INIT_SUCCESS, USER_PWD_INIT_FAILURE} from '../reducers/user';
import TokenService from "../service/TokenService";
import UserService from "../service/UserService";

function* logIn(action) {
    try {
        const result = yield call(UserService.login, action.data);
        TokenService.set(result.accessToken);
        
        console.log('saga / logIn : ' + result);
        yield put({
            type: LOG_IN_SUCCESS
            , data: result
        });
    } catch (error) {
        console.log(error);
        yield put({
            type: LOG_IN_FAILURE,
            error: error.response.data
        });
    }
}

function* logoutSaga(){
    try{
        yield call(UserService.logout);
        yield put({
            type: SIGN_OUT_SUCCESS
        });
    }catch(error){
        yield put({
            type: SIGN_OUT_FAILURE,
            error: error.response.data
        });
    }
}

function* signUp(action) {
    try{
        const result = yield call(UserService.signUp, action.data);
        console.log('saga / signUp : ' + result.code);
        yield put({
            type: SIGN_UP_SUCCESS
            , data: result
        });
    }catch(error){
        console.log(error);
        yield put({
            type: SIGN_UP_FAILURE,
            error: error.data
        });
    }
}

function* userList(action) {
    try{
        const result = yield call(UserService.userList, action.data);
        console.log('saga / userList : ' + result);
        yield put({
            type: USER_LIST_SUCCESS
            , data: result.data
        });
    }catch(error){
        console.log(error);
        yield put({
            type: USER_LIST_FAILURE,
            error: error.data
        });
    }
}

function* userStateMod(action) {
    try{
        const result = yield call(UserService.userStateMod, action.data);
        console.log('saga / userStateMod : ' + result);
        yield put({
            type: USER_STATE_MOD_SUCCESS
            , data: result.data
        });
    }catch(error){
        console.log(error);
        yield put({
            type: USER_STATE_MOD_FAILURE,
            error: error.data
        });
    }
}

function* userAuthMod(action) {
    try{
        const result = yield call(UserService.userAuthMod, action.data);
        console.log('saga / userAuthMod : ' + result);
        yield put({
            type: USER_AUTH_MOD_SUCCESS
            , data: result.data
        });
    }catch(error){
        console.log(error);
        yield put({
            type: USER_AUTH_MOD_FAILURE,
            error: error.data
        });
    }
}

function* userDel(action) {
    try{
        const result = yield call(UserService.userDel, action.data);
        console.log('saga / userDel : ' + result);
        yield put({
            type: USER_DEL_SUCCESS
            , data: result.data
        });
    }catch(error){
        console.log(error);
        yield put({
            type: USER_DEL_FAILURE,
            error: error.data
        });
    }
}

function* userPwdInit(action) {
    try{
        const result = yield call(UserService.userPwdInit, action.data);
        console.log('saga / userPwdInit : ' + result);
        yield put({
            type: USER_PWD_INIT_SUCCESS
            , data: result.data
        });
    }catch(error){
        console.log(error);
        yield put({
            type: USER_PWD_INIT_FAILURE,
            error: error.data
        });
    }
}

// 이벤트 리스너 같은 역할을 한다.
function* watchLogin() {
    console.log('saga / watchLogin');
    yield takeLatest(LOG_IN_REQUEST, logIn);
    yield takeLatest(SIGN_UP_REQUEST, signUp);
    yield takeLatest(USER_LIST_REQUEST, userList);
    yield takeLatest(USER_STATE_MOD_REQUEST, userStateMod);
    yield takeLatest(USER_AUTH_MOD_REQUEST, userAuthMod);
    yield takeLatest(USER_DEL_REQUEST, userDel);
    yield takeLatest(USER_PWD_INIT_REQUEST, userPwdInit);
    yield takeLatest(SIGN_OUT_REQUEST, logoutSaga);
}

export default function* userSaga(){
    yield all([
        fork(watchLogin),
    ])
}