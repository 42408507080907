import { all, fork, takeLatest, put, call, select} from "redux-saga/effects";
import { PAY_SPEC_UPLOAD_SUCCESS, PAY_SPEC_UPLOAD_FAIL, PAY_SPEC_UPLOAD_REQUEST, PAY_SPEC_LIST_SUCCESS, PAY_SPEC_DETAIL_SUCCESS, PAY_SPEC_DETAIL_FAIL, PAY_SPEC_LIST_FAIL, PAY_SPEC_LIST_REQUEST, PAY_SPEC_DETAIL_REQUEST } from "../reducers/paySpec";
import PaySpecService from "../service/PaySpecService";

function* paySpecList(action){
    try{
        const result = yield call(PaySpecService.paySpecList, action.data);
        console.log('saga / paySpecList : ' + result);

        yield put ({
            type : PAY_SPEC_LIST_SUCCESS
            , data : result.data
        })
    } catch (error) {
        console.log(error);
        yield put ({
            type : PAY_SPEC_LIST_FAIL,
            error : error.response.data
        })
    }
}

function* paySpecDetail(action){
    try{
        const result = yield call(PaySpecService.paySpecDetail, action.data);
        console.log('saga / paySpecDetail : ' + result);

        yield put ({
            type : PAY_SPEC_DETAIL_SUCCESS
            , data : result
        })
    } catch (error) {
        yield put ({
            type : PAY_SPEC_DETAIL_FAIL,
            error : error.response.data
        })
    }
}

function* addPaySpec(action) {
    try{
        const result = yield call(PaySpecService.add, action.data);
        console.log('saga / payspecAdd : ' + result);
        yield put ({
            type : PAY_SPEC_UPLOAD_SUCCESS
            , data : result
        })
    } catch (error) {
        yield put ({
            type : PAY_SPEC_UPLOAD_FAIL,
            error : error.response.data
        })
    }
}

function* watchPaySpec() {
    console.log('saga / watchPaySpec');
    yield takeLatest(PAY_SPEC_UPLOAD_REQUEST, addPaySpec);
    yield takeLatest(PAY_SPEC_LIST_REQUEST, paySpecList);
    yield takeLatest(PAY_SPEC_DETAIL_REQUEST, paySpecDetail);
}

export default function* paySpecSaga(){
    yield all([
        fork(watchPaySpec),
    ])
}