import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequestAction } from '../reducers/user';
import { useNavigate } from 'react-router-dom'
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import logo from '../image/sinalogo.png';

const SignIn = () => {
    const navigate = useNavigate();
    const [id, setId] = useState('CJ');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    const [isRemember, setIsRemember] = useState(true);
    const [cookies, setCookie, removeCookie] = useCookies(['rememberId']);
    const expires = new Date();
    
    const error = useSelector(
        (state) => state.user.logInError,
    );

    const success = useSelector(
        (state) => state.user.logInDone,
    );

    useEffect(()=>{
        if(error === null || error === undefined)return;
        switch (error.code) {
            case '99':
                alert(error.msg);
                break;
            case '98':
                //비밀번호 미설정
                alert(error.msg);
                //페이지 이동
                navigate("/signup", {state:id});
                break;
            case '01':
                //비밀번호 틀림
                alert(error.msg);
                break;
            case '02':
                alert(error.msg);
                break;
            default:
                alert(error.msg);
                break;
          }
    }, [error]);

    useEffect(()=>{
        if(success){
            if(isRemember){
                setCookie('rememberId', id, {maxAge: expires.getFullYear()+1});
            } else {
                removeCookie('rememberId');
            }
            navigate("/");
        }
    },[success]);

    const onChangeId = (e) => {
        setId(e.target.value.toUpperCase());
    };

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    }
    
    useEffect(() => {
        if(cookies.rememberId !== undefined) {
            setId(cookies.rememberId);
            setIsRemember(true);
        }
    }, []);

    const handleOnChange = (e) => {
        setIsRemember(e.target.checked);
        /*if(e.target.checked){
            setCookie('rememberId', id, {maxAge: 2000});
        } else {
            removeCookie('rememberId');
        }*/
    }

    const onSubmitFrom = (e)=>{
        e.preventDefault();
        dispatch(loginRequestAction({id, password}));
    };

    return (
        <>
            <div>
                <div style={{"paddingTop":"160px", "paddingBottom":"40px","textAlign":"center"}}>
                    <img src={logo} style={{"maxWidth": "90%", "height": "auto"}}/>
                </div>
                <Container className="panel">
                    <Form onSubmit={onSubmitFrom}>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Col sm>
                                <Form.Control type="input" value={id} onChange={onChangeId} placeholder="아이디" required/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Col sm>
                                <Form.Control type="password" value={password} onChange={onChangePassword} placeholder="비밀번호" required/>
                            </Col>
                        </Form.Group>
                        <div style={{"textAlign":"right"}}>
                            <label className="loginPage_text">
                                <input type="checkbox" onChange={(e)=> handleOnChange(e)} checked={isRemember}/> ID 저장하기
                            </label>
                        </div>
                        <br/>
                        <br/>
                        <div className="d-grid gap-1">
                            <Button variant="secondary" type="submit" >
                                로그인
                            </Button>
                        </div>
                    </Form>
                </Container>
            </div>
        </>
    );
};

export default SignIn;