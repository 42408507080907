import axios from 'axios';

const API_URL = process.env.REACT_APP_USER_API_URL+"user/";
const REACT_APP_TOKEN_KEY_NAME = process.env.REACT_APP_TOKEN_KEY_NAME;

export default class TokenService {
  
  static async tokenIss(){

    return new Promise (resolve => {
    	
      let token = localStorage.getItem(REACT_APP_TOKEN_KEY_NAME);
      let result = axios.post(API_URL+"checkAuth", null, {
        headers: {
          "Authorization" : `Bearer ${token}`,
        }
      }).then(function (response) {
        TokenService.set(response.data.accessToken);
        resolve(response.data.accessToken);
      }).catch(function (error) {
        console.log(error);
        resolve("");
      });
    });
  }
  
  static get() {
    return localStorage.getItem(REACT_APP_TOKEN_KEY_NAME);
  }
  static set(token) {
    localStorage.setItem(REACT_APP_TOKEN_KEY_NAME, token);
  }

  static remove() {
    localStorage.removeItem(REACT_APP_TOKEN_KEY_NAME);
  }
}
