import { combineReducers } from "redux";
import user from "./user";
import paySpec from "./paySpec";
import notice from "./notice";

const rootReducer = combineReducers({
    user,
    paySpec,
    notice
});

export default rootReducer;