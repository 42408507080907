import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAuthModRequestAction, userDelRequestAction, userListRequestAction, userStateModRequestAction, userPwdInintRequestAction} from "../../reducers/user";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import TokenService from "../../service/TokenService";
import NavBarComp from "../NavBarComp";

const ManageUserList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [state, setState] = useState("");
    const [point, setPoint] = useState("");

    const error = useSelector(
        (state) => state.user.userError,
    );

    const success = useSelector(
        (state) => state.user.userDone,
    );

    const data = useSelector(
        (state) => state.user.userData,
    );

    const modData = useSelector(
        (state) => state.user.userActData,
    );

    useEffect(() => {
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(userListRequestAction());
        });
    },[]);

    useEffect(()=>{
        if(modData){
            if(modData.code === "00"){
                dispatch(userListRequestAction());
            }
        }
    }, [modData]);

    const onChaneStateHandle = (e, id) => {
        let this_state = e.target.value;
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(userStateModRequestAction({"user_id":id, "user_state":this_state}));
        });
    }

    const onChaneAuthHandle = (e, id) => {
        let this_auth = e.target.value;
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(userAuthModRequestAction({"user_id":id, "user_auth":this_auth}));
        });
    }

    const onClickDel = (id) => {
        if (window.confirm("삭제 하시겠습니까?")) {
            TokenService.tokenIss().then(res => {
                if(res === ""){
                    alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                    navigate("/signin");
                }
                dispatch(userDelRequestAction({"user_id":id}));
            });
        }
    }

    const onClickPwdInit = (id, password) => {

        if(password === ''){
            alert('저장된 비밀번호가 없습니다.');
            return false;
        }

        if (window.confirm(id+"님 비밀번호를 초기화 하시겠습니까?")) {
            
            TokenService.tokenIss().then(res => {
                if(res === ""){
                    alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                    navigate("/signin");
                }
                dispatch(userPwdInintRequestAction({"user_id":id}));
            });
        }
    }

    const onChangeUserHandle = (e) => {
        setName(e.target.value);
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(userListRequestAction({name:e.target.value, state:state, point:point}));
        });
    }

    const onChangeStateHandle = (e) => {
        setState(e.target.value);
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(userListRequestAction({state:e.target.value, name:name, point:point}));
        });
    }

    const onChangePointHandle = (e) => {
        setPoint(e.target.value);
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(userListRequestAction({point:e.target.value, name:name, state:state}));
        });
    }

    return (
        <>
            <NavBarComp />
            <div style={{"paddingTop":"70px"}}>
                <div style={{"position":"fixed", "top":"61px", "width":"100%","zIndex":"999"}}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>지점</InputGroup.Text>
                        <Form.Select onChange={onChangePointHandle} defaultValue={""} style={{"width:":"100px", "display":"inline"}}>
                            <option value="">전체</option>
                            <option value="김포센터">김포센터</option>
                            <option value="월계점">월계점</option>
                            <option value="자양점">자양점</option>
                            <option value="하월곡점">하월곡점</option>
                        </Form.Select>
                        <InputGroup.Text>이름</InputGroup.Text>
                        <Form.Control onChange={onChangeUserHandle} defaultValue={name}/>
                        <InputGroup.Text>상태</InputGroup.Text>
                        <Form.Select onChange={onChangeStateHandle} defaultValue={""} style={{"width":"100px", "display":"inline"}}>
                            <option value="">선택</option>
                            <option value="00">재직</option>
                            <option value="01">사용중지</option>
                        </Form.Select>
                    </InputGroup>
                </div>
                <Table striped bordered hover style={{"marginTop":"50px"}}>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>점포명</th>
                            <th>이름</th>
                            <th>아이디</th>
                            <th>상태</th>
                            <th>권한</th>
                            <th></th>
                            <th>비밀번호 초기화</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Array.isArray(data)?
                            data.map((item, i) => {
                                return (
                                    <tr key={ i }>
                                        {
                                            item.user_password === ''?
                                            <td style={{"color":"#adb5bd"}}>{i+1}</td>:<td>{i+1}</td>
                                        }
                                        {
                                            item.user_password === ''?
                                            <td style={{"color":"#adb5bd"}}>{item.user_point}</td>:<td>{item.user_point}</td>
                                        }
                                        {
                                            item.user_password === ''?
                                            <td style={{"color":"#adb5bd"}}>{item.user_name}</td>:<td>{item.user_name}</td>
                                        }
                                        {
                                            item.user_password === ''?
                                            <td style={{"color":"#adb5bd"}}>{item.user_id}</td>:<td>{item.user_id}</td>
                                        }
                                       
                                        { item.user_id === 'admin'?
                                            <td>
                                                <Form.Select key={i} value={item.user_state} onChange={(e) => onChaneStateHandle(e, item.user_id, e.value)} disabled>
                                                    <option value="00">재직</option>
                                                    <option value="01">사용중지</option>
                                                </Form.Select>
                                            </td>
                                            :
                                            <td>
                                               <Form.Select key={i} value={item.user_state} onChange={(e) => onChaneStateHandle(e, item.user_id)}>
                                                    <option value="00">재직</option>
                                                    <option value="01">사용중지</option>
                                                </Form.Select>
                                            </td>
                                        }
                                        { item.user_id === 'admin'?
                                            <td>
                                                <Form.Select key={i} value={item.user_auth} onChange={(e) => onChaneAuthHandle(e, item.user_id)} disabled>
                                                    <option value="N">일반</option>
                                                    <option value="A">관리자</option>
                                                </Form.Select>
                                            </td>
                                            :
                                            <td>
                                                <Form.Select key={i} value={item.user_auth} onChange={(e) => onChaneAuthHandle(e, item.user_id)}>
                                                <option value="N">일반</option>
                                                    <option value="A">관리자</option>
                                                </Form.Select>
                                            </td>
                                        }
                                        { item.user_id != 'admin' &&
                                            <td>
                                                <Button variant="danger" onClick={() => onClickDel(item.user_id)}>삭제</Button>
                                            </td>
                                        }
                                        { item.user_id != 'admin' &&
                                            <td>
                                                <Button variant="danger" onClick={() => onClickPwdInit(item.user_id, item.user_password)}>초기화</Button>
                                            </td>
                                        }
                                    </tr>
                                )
                            })
                            :null
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default ManageUserList;