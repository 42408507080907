import React from "react";
import FadeLoader from "react-spinners/FadeLoader";

function Loading() {
  return (
    <div className="contentWrap" style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.5"}}>
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <FadeLoader
          color="#C63DEE"
          height={15}
          width={5}
          radius={2}
          margin={2}
        />
      </div>
    </div>
  );
}

export default Loading;