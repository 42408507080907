import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { noticeDetailRequestAction, noticeInitRequestAction, noticeModRequestAction } from "../../reducers/notice";
import TokenService from "../../service/TokenService";
import NavBarComp from "../NavBarComp";
import { Button, Form, InputGroup } from "react-bootstrap";

const ManageNoticeDetail = (props) => {
    const { seq } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    const error = useSelector(
        (state) => state.notice.noticeError,
    );

    const success = useSelector(
        (state) => state.notice.noticeDone,
    );

    const data = useSelector(
        (state) => state.notice.noticeData,
    );

    const modError = useSelector(
        (state) => state.notice.noticeActError,
    );

    const modSuccess = useSelector(
        (state) => state.notice.noticeActDone,
    );

    const modData = useSelector(
        (state) => state.notice.noticeActData,
    );

    useEffect(() => {
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(noticeDetailRequestAction(seq));
        });
    },[]);

    useEffect(()=>{
        if(data !== null){
            setTitle(data.title);
            setContent(data.content);
        }
    }, [data]);

    useEffect(()=>{
        if(modError === null)return;
        alert('에러가 발생하였습니다.');
    }, [modError]);

    useEffect(()=>{
        if(modData){
            if(modData.code === "00"){
                alert("수정이 완료 되었습니다.");
                dispatch(noticeInitRequestAction());
                navigate("/manage/notice/list");
            }
        }
    }, [modData]);


    const onSubmitFrom =(e)=>{
        e.preventDefault();
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(noticeModRequestAction({seq:seq, title:title, content:content}));
        });
    };

    const onChangeTitle = (e) =>{
        setTitle(e.target.value);
    }
    
    const onChangeContent = (e) =>{
        setContent(e.target.value);
    }

    return (
        <>
            <NavBarComp />
            수정
            <div style={{"width":"50%", "paddingTop":"70px"}}>
                <form onSubmit={onSubmitFrom}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>제목</InputGroup.Text>
                        <Form.Control onChange={onChangeTitle} value={title}/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>내용</InputGroup.Text>
                        <Form.Control as="textarea" onChange={onChangeContent} value={content} />
                    </InputGroup>
                    <div style={{"textAlign":"right"}}>
                        <Button type="primary">등록</Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ManageNoticeDetail;