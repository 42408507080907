import axios from "axios";
import TokenService from "./TokenService";

const API_URL = process.env.REACT_APP_USER_API_URL;

export default class NoticeService {

    static async noticeList(data) {
        const token = TokenService.get();
        let result = await axios.post(API_URL+"notice/list", data,{
            headers: {
                "Authorization" : `Bearer ${token}`,
            }
        });
        return result.data;
    }

    static async noticeUseYnMod(data) {
        const token = TokenService.get();

        let result = await axios.post(API_URL+"notice/mod/useyn", data,{
            headers: {
                "Authorization" : `Bearer ${token}`,
            }
        });
        return result;
    }

    static async noticeDel(data) {
        const token = TokenService.get();

        let result = await axios.post(API_URL+"notice/del", data,{
            headers: {
                "Authorization" : `Bearer ${token}`,
            }
        });
        return result;
    }

    static async noticeReg(data) {
        const token = TokenService.get();

        let result = await axios.post(API_URL+"notice/reg", data,{
            headers: {
                "Authorization" : `Bearer ${token}`,
            }
        });
        return result;
    }

    static async noticeMod(data) {
        const token = TokenService.get();

        let result = await axios.post(API_URL+"notice/mod", data,{
            headers: {
                "Authorization" : `Bearer ${token}`,
            }
        });
        return result;
    }

    static async noticeDetail(data) {
        const token = TokenService.get();

        let result = await axios.post(API_URL+"notice/detail", {seq:data},{
            headers: {
                "Authorization" : `Bearer ${token}`,
            }
        });
        return result.data;
    }
}