export const initialState = {
    loading : false,
    paySpecDone : false,
    paySpecError : false,
    paySpecActDone : false,
    paySpecActError : false,
    paySpecActData : null,
    paySpecData : null,
};

export const PAY_SPEC_UPLOAD_REQUEST = 'PAY_SPEC_UPLOAD_REQUEST';
export const PAY_SPEC_UPLOAD_SUCCESS = 'PAY_SPEC_UPLOAD_SUCCESS';
export const PAY_SPEC_UPLOAD_FAIL = 'PAY_SPEC_UPLOAD_FAIL';

export const PAY_SPEC_LIST_REQUEST = 'PAY_SPEC_LIST_REQUEST';
export const PAY_SPEC_LIST_SUCCESS = 'PAY_SPEC_LIST_SUCCESS';
export const PAY_SPEC_LIST_FAIL = 'PAY_SPEC_LIST_FAIL';

export const PAY_SPEC_DETAIL_REQUEST = 'PAY_SPEC_DETAIL_REQUEST';
export const PAY_SPEC_DETAIL_SUCCESS = 'PAY_SPEC_DETAIL_SUCCESS';
export const PAY_SPEC_DETAIL_FAIL = 'PAY_SPEC_DETAIL_FAIL';

export const PAYSPEC_INIT_STATE = "PAYSPEC_INIT_STATE";

export const paySpecUploadRequest = (data) => {
    console.log('reducer / paySpecListRequest');
    return{
        type : PAY_SPEC_UPLOAD_REQUEST,
        data : data
    }
}

export const paySpecListRequest = (data) => {
    console.log('reducer / paySpecListRequest');
    return {
        type: PAY_SPEC_LIST_REQUEST,
        data: data
    }
}

export const paySpecDetailRequest = (data) => {
    console.log('reducer / paySpecDetailRequest');
    return {
        type: PAY_SPEC_DETAIL_REQUEST,
        data: data
    }
}

export const paySpecInitRequestAction = () => {
    console.log('reducer / paySpecInitRequestAction');
    return {
        type : PAYSPEC_INIT_STATE
    }
}

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case PAY_SPEC_UPLOAD_REQUEST:{
            console.log('reducer / 급여내역 업로드 요청');
            return {
                ...state,
                loading : true,
                paySpecActDone : false,
                paySpecActError : null,
            };
        }
        case PAY_SPEC_UPLOAD_SUCCESS:{
            console.log('reducer / 급여내역 업로드 완료');
            return {
                ...state,
                loading : false,
                paySpecActDone : true,
                paySpecActData : action.data
            };
        }
        case PAY_SPEC_UPLOAD_FAIL:{
            console.log('reducer / 급여내역 업로드 실패');
            return {
                loading : false,
                paySpecActError : action.error
            };
        }
        case PAY_SPEC_LIST_REQUEST:{
            console.log('reducer / 급여내역 목록 조회 요청');
            return {
                ...state,
                loading : true,
                paySpecDone : false,
                paySpecError : null,
            };
        }
        case PAY_SPEC_LIST_SUCCESS:{
            console.log('reducer / 급여내역 목록 조회 완료');
            return {
                ...state,
                loading : false,
                paySpecDone : true,
                paySpecData : action.data
            };
        }
        case PAY_SPEC_LIST_FAIL:{
            console.log('reducer / 급여내역 목록 조회 실패');
            return {
                loading : false,
                paySpecError : action.error
            };
        }
        case PAY_SPEC_DETAIL_REQUEST:{
            console.log('reducer / 급여내역 상세 조회 요청');
            return {
                ...state,
                loading : true,
                paySpecDone : false,
                paySpecError : null,
            };
        }
        case PAY_SPEC_DETAIL_SUCCESS:{
            console.log('reducer / 급여내역 상세 조회 완료');
            return {
                ...state,
                loading : false,
                paySpecDone : true,
                paySpecData : action.data
            };
        }
        case PAY_SPEC_DETAIL_FAIL:{
            console.log('reducer / 급여내역 상세 조회 실패');
            return {
                loading : false,
                paySpecError : action.error
            };
        }
        case PAYSPEC_INIT_STATE:{
            console.log('reducer / 급여내역 스테이트 초기화');
            return initialState;
        }
        default: {
            return{
                ...state,
            }
        }    
    }
};

export default reducer;