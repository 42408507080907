import axios from 'axios';
import jwt_decode from "jwt-decode";
import TokenService from './TokenService';

const FCM_API_URL   = process.env.REACT_APP_FCM_API_URL;
const API_URL       = process.env.REACT_APP_USER_API_URL;
const HOME_URL      = process.env.REACT_APP_HOME_URL;
const serverKey     = process.env.REACT_APP_FCM_SERVER_KEY;

export default class FcmService {

    static async sendFcmMsg(data){
        let token = TokenService.get();
        let decoded = jwt_decode(token);
        let msg = "";

        if(token != null){
            let targetUserList = await axios.post(API_URL+"user/fcm/token", data, {
                headers: {
                    "Authorization" : `Bearer ${token}`,
                }
            });
            
            targetUserList.data.data.map((item, i) => {
                const userToken = item.user_push_token;
                const userId = decoded.user_id;
                const val = data.val;
                const title = data.title;
                const body = data.content;
                const type = data.type;
                
                let bodyObj = "";

                if(type === "notice") {
                    const url = HOME_URL+"notice/detail/"+val;
                    bodyObj = {
                        "to" : userToken,
                        "notification" : {
                            "title" : "[공지] "+title
                            , "body" : body
                            , "urlLink" : url
                        }, "data": 
                        {
                            "title" : "[공지] "+title
                            , "body" : body
                            , "urlLink" : url
                        }
                    }
                }else{
                    const url = HOME_URL + "payspec/"+val;
                    bodyObj = {
                        "to" : userToken
                        , "priority" : "high"
                        , "notification" : {
                            "title" : item.user_name+"님 "+title
                            , "body" : body
                            , "urlLink" : url
                        }, "data": 
                        {
                            "title" : item.user_name+"님 "+title
                            , "body" : body
                            , "urlLink" : url
                        }
                    }

                }
                
                axios.post(FCM_API_URL, bodyObj , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': serverKey
                    }
                }).then(function (response) {
                }).catch(function (error) {
                    return "fail";
                });
            });
            return "ok";
        }
    }
}
