import { useNavigate } from 'react-router-dom';
import TokenService from "../service/TokenService";
import { useEffect, useState } from "react";
import NavBarComp from './NavBarComp';
import NavBarBottomComp from './NavBarBottomComp';
import title from '../image/sinatitle.png';

const Index = () => {
    const navigate = useNavigate();
    
    useEffect(()=>{
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
        });
    }, []);
    
    return (
        <>
            <NavBarComp/>
            <div style={{"paddingTop":"350px", "marginBottom":"50px"}}>
            <img src={title} style={{"maxWidth": "100%", "height": "auto"}}/>
            </div>
            <NavBarBottomComp />
        </>
    )
}

export default Index;