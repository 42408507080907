import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { noticeListRequestAction } from "../reducers/notice";
import TokenService from "../service/TokenService";
import NavBarBottomComp from "./NavBarBottomComp";
import NavBarComp from "./NavBarComp";

const NoticeList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const error = useSelector(
        (state) => state.notice.noticeError,
    );

    const success = useSelector(
        (state) => state.notice.noticeDone,
    );

    const data = useSelector(
        (state) => state.notice.noticeData,
    );

    useEffect(() => {
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(noticeListRequestAction({"useYn":"Y"}));
        });
    },[]);

    useEffect(()=>{
        if(error === null)return;
        alert('목록 조회 중 에러가 발생 하였습니다.');
    }, [error]);

    const onclickDetail = (seq) => {
        navigate("/notice/detail/"+seq);
    }

    return (
        
        <>
            <NavBarComp />
            <div style={{"paddingTop":"70px", "marginBottom":"50px"}}>
                <div style={{"fontSize":"20px", "fontWeight":"bold","padding":"5px"}}>공지사항</div>
                <Table>
                    <thead>
                        <tr>
                            <th style={{"width":"20%"}}>번호</th>
                            <th style={{"width":"80%"}}>제목</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        Array.isArray(data)? data.map((item, i) => {
                            return ( 
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td onClick={()=> onclickDetail(item.seq)} style={{"cursor":"pointer","fontWeight":"bold"}}>{ item.title }</td>
                                </tr>
                            ) 
                        }) :null 
                    } 
                    </tbody>
                </Table>
            </div>
            <NavBarBottomComp />
        </>
    )
}

export default NoticeList;