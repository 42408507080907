export const initialState = {
    loading : false,
    noticeDone : false, 
    noticeError : null,
    noticeData : null,
    noticeActDone : false,
    noticeActError : null,
    noticeActData : null,
};

export const NOTICE_LIST_REQUEST = "NOTICE_LIST_REQUEST";
export const NOTICE_LIST_SUCCESS = "NOTICE_LIST_SUCCESS";
export const NOTICE_LIST_FAILURE = "NOTICE_LIST_FAILURE";

export const NOTICE_DETAIL_REQUEST = "NOTICE_DETAIL_REQUEST";
export const NOTICE_DETAIL_SUCCESS = "NOTICE_DETAIL_SUCCESS";
export const NOTICE_DETAIL_FAILURE = "NOTICE_DETAIL_FAILURE";

export const NOTICE_REG_REQUEST = "NOTICE_REG_REQUEST";
export const NOTICE_REG_SUCCESS = "NOTICE_REG_SUCCESS";
export const NOTICE_REG_FAILURE = "NOTICE_REG_FAILURE";

export const NOTICE_MOD_REQUEST = "NOTICE_MOD_REQUEST";
export const NOTICE_MOD_SUCCESS = "NOTICE_MOD_SUCCESS";
export const NOTICE_MOD_FAILURE = "NOTICE_MOD_FAILURE";

export const NOTICE_DEL_REQUEST = "NOTICE_DEL_REQUEST";
export const NOTICE_DEL_SUCCESS = "NOTICE_DEL_SUCCESS";
export const NOTICE_DEL_FAILURE = "NOTICE_DEL_FAILURE";

export const NOTICE_USE_YN_MOD_REQUEST = "NOTICE_USE_YN_MOD_REQUEST";
export const NOTICE_USE_YN_MOD_SUCCESS = "NOTICE_USE_YN_MOD_SUCCESS";
export const NOTICE_USE_YN_MOD_FAILURE = "NOTICE_USE_YN_MOD_FAILURE";

export const NOTICE_INIT_STATE = "NOTICE_INIT_STATE";

//공지사항 목록 조회
export const noticeListRequestAction = (data) => {
    console.log("reducer / noticeListRequestAction");
    return {
        type : NOTICE_LIST_REQUEST,
        data : data
    }
}

export const noticeUseYnModRequestAction =  (data) => {
    console.log(`reducer / noticeUseYnModRequestAction ${data}`);
    return {
        type : NOTICE_USE_YN_MOD_REQUEST,
        data : data,
    }
}

export const noticeDelRequestAction = (data) => {
    console.log('reducer / noticeDelRequestAction');
    return {
        type : NOTICE_DEL_REQUEST,
        data : data
    }
}

export const noticeRegRequestAction = (data) => {
    console.log("reducer / noticeRegRequestAction");

    return {
        type : NOTICE_REG_REQUEST,
        data : data
    }
}

export const noticeModRequestAction = (data) => {
    console.log("reducer / noticeModeRequestAction");

    return {
        type : NOTICE_MOD_REQUEST,
        data : data
    }
}

export const noticeDetailRequestAction = (data) => {
    console.log("reducer / noticeDetailRequestAction");
    return {
        type : NOTICE_DETAIL_REQUEST,
        data : data
    }
}

export const noticeInitRequestAction = () => {
    console.log('reducer / noticeInitRequestAction');
    return {
        type : NOTICE_INIT_STATE
    }
}

//공지사항 수정
/*export const noticeModRequestAction = (data) => {
    console.log("reducer / noticeModRequestAction");

    return {
        type : NOTICE_MOD_REQUEST,
        data : data
    }
}

//공지사항 삭제
export const noticeDelRequestAction = (data) => {
    console.log("reducer / noticeDelRequestAction");

    return {
        type : NOTICE_DEL_REQUEST,
        data : data
    }
}*/

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case NOTICE_LIST_REQUEST:{
            console.log('reducer / 공지사항 목록 조회 요청');
            return {
                ...state,
                loading : true,
                noticeDone : false,
                noticeError : null,
            };
        }
        case NOTICE_LIST_SUCCESS:{
            console.log('reducer / 공지사항 목록 조회 완료');
            return {
                ...state,
                loading : false,
                noticeDone : true,
                noticeData : action.data
            };
        }
        case NOTICE_LIST_FAILURE:{
            console.log('reducer / 공지사항 목록 조회 실패');
            return {
                loading : false,
                noticeError : action.error
            };
        }
        case NOTICE_USE_YN_MOD_REQUEST:{
            console.log('reducer / 공지사항 노출 여부 수정 요청');
            return {
                ...state,
                loading : true,
                noticeActDone : false,
                noticeActError : null,
            };
        }
        case NOTICE_USE_YN_MOD_SUCCESS:{
            console.log('reducer / 공지사항 노출 여부 수정 완료');
            return {
                ...state,
                loading : false,
                noticeActDone : true,
                noticeActData : action.data
            };
        }
        case NOTICE_USE_YN_MOD_FAILURE:{
            console.log('reducer / 공지사항 노출 여부 수정 실패');
            return {
                loading : false,
                noticeActError : action.error
            };
        }
        case NOTICE_DEL_REQUEST:{
            console.log('reducer / 공지사항 삭제 요청');
            return {
                ...state,
                loading : true,
                noticeActDone : false,
                noticeActError : null,
            };
        }
        case NOTICE_DEL_SUCCESS:{
            console.log('reducer / 공지사항 삭제 완료');
            return {
                ...state,
                loading : false,
                logInActDone : true,
                noticeActData : action.data
            };
        }
        case NOTICE_DEL_FAILURE:{
            console.log('reducer / 공지사항 삭제 실패');
            return {
                loading : false,
                noticeActError : action.error
            };
        }
        case NOTICE_REG_REQUEST:{
            console.log('reducer / 공지사항 등록 요청');
            return {
                ...state,
                loading : true,
                noticeActDone : false,
                noticeActError : null,
            };
        }
        case NOTICE_REG_SUCCESS:{
            console.log('reducer / 공지사항 등록 완료');
            return {
                ...state,
                loading : false,
                noticeActDone : true,
                noticeActData : action.data
            };
        }
        case NOTICE_REG_FAILURE:{
            console.log('reducer / 공지사항 등록 실패');
            return {
                loading : false,
                noticeActError : action.error
            };
        }
        case NOTICE_MOD_REQUEST:{
            console.log('reducer / 공지사항 수정 요청');
            return {
                ...state,
                loading : true,
                noticeActDone : false,
                noticeActError : null,
            };
        }
        case NOTICE_MOD_SUCCESS:{
            console.log('reducer / 공지사항 수정 완료');
            return {
                ...state,
                loading : false,
                noticeActDone : true,
                noticeActData : action.data
            };
        }
        case NOTICE_MOD_FAILURE:{
            console.log('reducer / 공지사항 수정 실패');
            return {
                loading : false,
                noticeActError : action.error
            };
        }
        case NOTICE_DETAIL_REQUEST:{
            console.log('reducer / 공지사항 상세 조회 요청');
            return {
                ...state,
                loading : true,
                noticeDone : false,
                noticeError : null,
            };
        }
        case NOTICE_DETAIL_SUCCESS:{
            console.log('reducer / 공지사항 상세 조회 완료');
            return {
                ...state,
                loading : false,
                noticeDone : true,
                noticeData : action.data
            };
        }
        case NOTICE_DETAIL_FAILURE:{
            console.log('reducer / 공지사항 상세 조회 실패');
            return {
                loading : false,
                noticeError : action.error
            };
        }
        case NOTICE_INIT_STATE:{
            console.log('reducer / 공지사항 스테이트 초기화');
            return initialState;
        }

        /*case NOTICE_MOD_REQUEST:{
            console.log('reducer / 공지사항 수정 요청');
            return {
                ...state,
                loading : true,
                noticeDone : false,
                noticeError : null,
            };
        }
        case NOTICE_MOD_SUCCESS:{
            console.log('reducer / 공지사항 수정 완료');
            return {
                ...state,
                loading : false,
                noticeDone : true,
                noticeData : action.data
            };
        }
        case NOTICE_MOD_FAILURE:{
            console.log('reducer / 공지사항 수정 실패');
            return {
                loading : false,
                noticeError : action.error
            };
        }

        */
        default: {
            return{
                ...state,
            }
        }    
    }
}

export default reducer;