import NavBarComp from "./NavBarComp";

const NotFound = () => {

    return (
        <>
            <NavBarComp />
            <div style={{"paddingTop":"70px"}}>존재하지 않는 페이지 입니다.</div>
        </>
    )
}

export default NotFound;