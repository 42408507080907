import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Index from './components/Index';
import NotFound from './components/NotFound';
import SignIn from './components/SignIn';
import SignOut from './components/SignOut';
import SignUp from './components/SignUp';
import ManagePaySpec from './components/manage/ManagePaySpec';
import ManageNoticeList from './components/manage/ManageNoticeList';
import ManageNoticeReg from './components/manage/ManageNoticeReg';
import ManageNoticeDetail from './components/manage/ManageNoticeDetail';
import PaySpec from './components/PaySpec';
import ManageUserList from './components/manage/ManageUserList';
import NoticeDetail from './components/NoticeDetail';
import NoticeList from './components/NoticeList';
import Modal from './components/Modal';
import { useState } from 'react';
import jwt_decode from "jwt-decode";
import TokenService from './service/TokenService';

function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [cont, setCont] = useState("");
  const [url, setUrl] = useState("");

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="App">
      <Modal open={modalOpen} close={closeModal} url={url} header={title}>{cont}</Modal>

      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Index />}></Route>
            <Route path="/signin" element={<SignIn />}></Route>
            <Route path="/signout" element={<SignOut />}></Route>
            <Route path="/signup" element={<SignUp />}></Route>
            <Route path="/payspec/:seq" element={<PaySpec />}></Route>
            <Route path="/notice/list" element={<NoticeList />}></Route>
            <Route path="/notice/detail/:seq" element={<NoticeDetail />}></Route>
            <Route path="/manage/user/list" element={<ManageUserList/>}></Route>
            <Route path="/manage/payspec" element={<ManagePaySpec />}></Route>
            <Route path="/manage/notice/list" element={<ManageNoticeList />}></Route>
            <Route path="/manage/notice/reg" element={<ManageNoticeReg />}></Route>
            <Route path="/manage/notice/detail/:seq" element={<ManageNoticeDetail />}></Route>
            {/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
