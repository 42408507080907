import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { signOutRequestAction } from "../reducers/user";

const SignOut = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const signOutDone = useSelector (
        (state) => state.user.signOutDone,
    );

    useEffect(()=>{
        if(signOutDone){
            navigate("/signin");
        }
    }, [signOutDone]);


    useEffect(()=>{
        dispatch(signOutRequestAction());
    }, []);
    return (
        <></>
    );
};

export default SignOut;