import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paySpecDetailRequest } from "../reducers/paySpec";
import { Nav , Container, Row, Col, Form, InputGroup, Table, Button} from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import TokenService from "../service/TokenService";
import NavBarComp from "./NavBarComp";
import NavBarBottomComp from "./NavBarBottomComp";
import html2canvas from 'html2canvas';
import { BeatLoader } from 'react-spinners';
import Loading from "./Loading";

const PaySpec = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { seq } = useParams();
    const [month, setMonth] = useState(seq.substring(4,6));
    const [year, setYear] = useState(seq.substring(0,4));
    const [tab , tabChange] = useState(0);

    const error = useSelector(
        (state) => state.paySpec.paySpecError,
    );

    const success = useSelector(
        (state) => state.paySpec.paySpecDone,
    );

    const data = useSelector(
        (state) => state.paySpec.paySpecData,
    );

    useEffect(()=>{
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(paySpecDetailRequest({"year":year, "month":month}));
        });
    }, []);

    const onChangeMonth = (e) => {
        setMonth(e.target.value);
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(paySpecDetailRequest({"month":e.target.value, "year":year}));
        });
    }

    const onChangeYear = (e) => {
        setYear(e.target.value);
        TokenService.tokenIss().then(res => {
            if(res === ""){
                alert('권한 및 계정 정보가 올바르지 않습니다.\n로그인 페이지로 이동 합니다.');
                navigate("/signin");
            }
            dispatch(paySpecDetailRequest({"year":e.target.value, "month":month}));
        });
    }

    return (
        <>
            <NavBarComp />
            <div  style={{"paddingTop":"70px", "marginBottom":"50px"}}>
                <div style={{"position":"fixed", "top":"61px", "width":"100%", "zIndex":"999"}}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>조회</InputGroup.Text>
                        <Form.Select defaultValue={year} onChange={onChangeYear}>
                            <option value="2022">2022년</option>
                            <option value="2023">2023년</option>
                            <option value="2024">2024년</option>
                        </Form.Select>
                        <Form.Select defaultValue={month} onChange={onChangeMonth}>
                            <option value="01">01월</option>
                            <option value="02">02월</option>
                            <option value="03">03월</option>
                            <option value="04">04월</option>
                            <option value="05">05월</option>
                            <option value="06">06월</option>
                            <option value="07">07월</option>
                            <option value="08">08월</option>
                            <option value="09">09월</option>
                            <option value="10">10월</option>
                            <option value="11">11월</option>
                            <option value="12">12월</option>
                        </Form.Select>
                    </InputGroup>
                </div>
                
                <div style={{"marginTop":"40px"}}>
                    <Nav variant="tabs" defaultActiveKey="link0">
                        <Nav.Item>
                                <Nav.Link eventKey="link0" onClick={()=>{tabChange(0)}}>운행내역</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                                <Nav.Link eventKey="link1" onClick={()=>{tabChange(1)}}>세금계산서</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    {data?
                        <TabContent tab={tab} data={data} />
                    :null}
                </div>
            </div>
            <NavBarBottomComp />
        </>
    )
}

const TabContent = ({tab, data}) => {
    const captureRef1 = useRef(null);
    const captureRef2 = useRef(null);
    const [loading1, setLoading1] = useState(null);
    const [loading2, setLoading2] = useState(null);

    useEffect(() => {
        // ref는 항상 존재여부를 검사하고 사용해야 한다(단축평가 Good!)
        captureRef1.current && captureRef1.current.focus();
        captureRef2.current && captureRef2.current.focus();
    });
    
    function commaValue(val){
        if(val != undefined){
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }

    function subStringVal(idx, data) {
        if(data !== undefined){
            return data.substring(data.length-idx-1, data.length-idx);
        }
    }

    function subStringMonthDay(type, data){
        if(data !== undefined){
            if(type === "year"){
                return data.substring(0,4);
            } else if(type === "month"){
                return data.substring(4,6);
            } else if( type === "day"){
                return data.substring(6,8);
            }
        }
    }

    function nvl(val){
        if (val == null || val == 'null' || val == undefined || val == '') {
            return 0;
        }
        return val;
    }

    const handleCapture1 = () => {
        setLoading1(true);

        if (window.confirm("운행 내역을 저장하시겠습니까?")) {
            
            if (captureRef1.current) {
                html2canvas(captureRef1.current)
                .then((canvas) => {
                    const imageData = canvas.toDataURL("image/png");
                    const chunkSize = 1024;
                    const chunks = [];
                    for (var i = 0, len = imageData.length; i < len; i += chunkSize) {
                        chunks.push(imageData.substr(i, chunkSize));
                    }
                    window.android.captureContent(chunks, "운행내역_"+subStringMonthDay("year", data.월일)+subStringMonthDay("month", data.월일));
                    setLoading1(false);
                    
                    /*const link = document.createElement('a');
                    link.href = imageData;
                    link.download = subStringMonthDay("year", data.월일)+subStringMonthDay("month", data.월일)+'월.png';
                    link.click();*/
                });
            }
        } else {
            setLoading1(false);
        }
    }

    const handleCapture2 = () => {
        setLoading2(true);

        //window.android.captureContent(document.getElementById("tabTaxSpec").clientWidth, document.getElementById("tabTaxSpec").clientHeight);
        if (window.confirm("세금 계산서를 저장하시겠습니까?")) {
            
            if (captureRef2.current) {
                html2canvas(captureRef2.current)
                .then((canvas) => {
                    const imageData = canvas.toDataURL("image/png");
                    const chunkSize = 1024;
                    const chunks = [];
                    for (var i = 0, len = imageData.length; i < len; i += chunkSize) {
                        chunks.push(imageData.substr(i, chunkSize));
                    }
                    window.android.captureContent(chunks, "세금계산서_"+subStringMonthDay("year", data.월일)+subStringMonthDay("month", data.월일));
                    setLoading2(false);
                    
                    /*const link = document.createElement('a');
                    link.href = imageData;
                    link.download = subStringMonthDay("year", data.월일)+subStringMonthDay("month", data.월일)+'월.png';
                    link.click();*/
                });
            }
        } else {
            setLoading2(false);
        }
    }

    


    const loadingClose = () => {
        setLoading1(false);
    }

    const loadingClose2= () => {
        setLoading2(false);
    }
    return <div style={{"overflow":"auto"}}>
        {
            [
                <div>
                    <div ref={captureRef1} style={{"marginBottom":"50px"}}>
                        {loading1 && <Loading />}
                        <Table striped bordered hover size="sm" style={{"fontSize":"12px"}}>
                            <tbody>
                                <tr>
                                    <td>점포명</td>
                                    <td>{data.점포명}</td>
                                </tr>

                                <tr>
                                    <td>기사명</td>
                                    <td>{data.배송기사명}</td>
                                </tr>
                                <tr>
                                    <td>운행차량</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>차량번호</td>
                                    <td>{data.차량번호}</td>
                                </tr>
                                <tr>
                                    <td>운행일</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>

                        <Table striped bordered hover size="sm" style={{"fontSize":"12px"}}>
                            <thead>
                                <tr>
                                    <th>지급항목</th>
                                    <th>산정내역</th>
                                    <th>정산자료</th>
                                    <th>금액</th>
                                </tr>
                            </thead>
                            
                                {data.점포명 === "김포센터"?
                                    <tbody>
                                        <tr>
                                            <td>기본급</td>
                                            <td>운행일수 日 120,000원</td>
                                            <td>{commaValue(nvl(data.운행일수))} 日</td>
                                            <td>{commaValue(nvl(data.기본도급료))}</td>
                                        </tr>
                                        <tr>
                                            <td>추가유류보조금</td>
                                            <td>
                                                - 총운행거리/연비*유류비평균가<br/>
                                                (부가세별도)(7.0km/ℓ)))
                                            </td>
                                            <td>{commaValue(nvl(data.총운행거리))} km</td>
                                            <td>{commaValue(nvl(data.운행거리INC유류비))}</td>
                                        </tr>
                                        <tr>
                                            <td>운행거리</td>
                                            <td>
                                                - 일 km 수당 : <br/>
                                                71km 이상 2,300원<br/>
                                                91km 이상 5,000원<br/>
                                                101km 이상 7,500원<br/>
                                                111km 이상 12,000원<br/>
                                                131km 이상 15,500원<br/>
                                            </td>
                                            <td>{commaValue(nvl(data.총운행거리))} km</td>
                                            <td>{commaValue(nvl(data.배송거리INC배송구간))}</td>
                                        </tr>
                                        <tr>
                                            <td>인센티브</td>
                                            <td>
                                                일 기준<br/>
                                                34건 초과 건당 2,900원<br/>
                                                37건 초과 건당 3,200원<br/>
                                                40건 초과 건당 3,600원<br/>
                                                43건 초과 건당 4,000원<br/>
                                            </td>
                                            <td>{commaValue(nvl(data.배송처수))} 건</td>
                                            <td>{commaValue(nvl(data.배송건INC추가배송건))}</td>
                                        </tr>
                                        <tr>
                                            <td>바스켓추가비용</td>
                                            <td>
                                                - 건당 박스 수 4박스 초과시<br/>
                                                박스당 400원
                                            </td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.박스INC추가박스))}</td>
                                        </tr>
                                        <tr>
                                            <td>제수당</td>
                                            <td>피복/감가/세차비 포함</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.제수당))}</td>
                                        </tr>
                                        <tr>
                                            <td>새벽/굿모닝 수당</td>
                                            <td>
                                                굿모닝조(G조) 조출 수당 건당 350원<br/>
                                                새벽조(2회전) 조출/피킹 수당 건당 500원
                                            </td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.굿모닝INC조출건수지원))}</td>
                                        </tr>
                                        <tr>
                                            <td>온도관제미준수</td>
                                            <td>1회 이상 미준수시 적용</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.온도관제위반3점이상05삭감))}</td>
                                        </tr>
                                        <tr>
                                            <td>통행료/주차비</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.특수권역운영INC톨게이트지원금))}</td>
                                        </tr>
                                        {/*<tr>
                                            <td>백신 휴차 지원금</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.백신지원격리지원금))}</td>
                                        </tr>*/}
                                        <tr>
                                            <td>근속수당</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.근속수당))}</td>
                                        </tr>
                                        <tr>
                                            <td>GPS/용차비</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.gps용차비공제))}</td>
                                        </tr>
                                        <tr>
                                            <td>패널티공제</td>
                                            <td>
                                                -상품누락, 오배송으로 인한<br/>
                                                패널티(이마트 공제기준)
                                            </td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.공제기사변제))}</td>
                                        </tr>
                                        <tr>
                                            <td>관리비(지입료)</td>
                                            <td>조은운수/대한통운 지입료</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.관리비및부가세))}</td>
                                        </tr>
                                        
                                        <tr style={{"textAlign":"center", "textAlign": "center", "backgroundColor": "yellow", "fontWeight": "bold", "fontSize": "14px"}}>
                                            <td colSpan="3">
                                                총합(부가세 포함) 
                                            </td>
                                            <td>{commaValue(Math.floor(data.기사지급액지입료제외*1.1))}</td>
                                        </tr>
                                        <tr>
                                            <td>회차 및 갱신보험</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.회차및갱신보험))}</td>
                                        </tr>
                                        <tr>
                                            <td>환경부담금</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.환경부담금))}</td>
                                        </tr>
                                    
                                        <tr>
                                            <td>협회비</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.협회비))}</td>
                                        </tr>

                                        <tr>
                                            <td>고용/산재보험</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.고용산재보험))}</td>
                                        </tr>

                                        <tr>
                                            <td>과태료공제</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.과태료공제))}</td>
                                        </tr>

                                        <tr>
                                            <td>전월미수 및 기타비용</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.전월미수및기타비용))}</td>
                                        </tr>

                                        <tr>
                                            <td>기타분납금</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.기타분납금))}</td>
                                        </tr>
                                        <tr style={{"textAlign":"center", "textAlign": "center", "backgroundColor": "yellow", "fontWeight": "bold", "fontSize": "14px"}}>
                                            <td colSpan="3">공제 후 운송료 지급 합계</td>
                                            <td>{commaValue(nvl(data.기사급여이체))}</td>
                                        </tr>
                                    </tbody>
                                :data.점포명 === "월계점"?
                                    <tbody>
                                        <tr>
                                            <td>기본 도급</td>
                                            <td>운행일수 1일당 123,076원</td>
                                            <td>{commaValue(nvl(data.도급일))}일</td>
                                            <td>{commaValue(nvl(data.기본도급료공제대상))}</td>
                                        </tr>

                                        <tr>
                                            <td>건수인센티브</td>
                                            <td>
                                                2회전/일 건수(30건 초과 건당 2,700원 / 35건 초과 건당<br/>
                                                3,200원 / 40건 초과 건당 3,500원 / 45건 초과 건당 4,000원)<br/>
                                                1회전/일 건수(15건 초과 건당 2,500원 / 17건 초과 건당 3,000원 / 20건 초과 건당 3,500원 / 22건 초과 건당 4,000원)<br/>
                                            </td>
                                            <td>{commaValue(nvl(data.총배송처수))} 건</td>
                                            <td>{commaValue(nvl(data.건수인센티브))}</td>
                                        </tr>
                                        
                                        <tr>
                                            <td>온도관제미준수</td>
                                            <td>온도관제 미준수시 1日 기본도급료 1%공제</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.온도관제미설치))}</td>
                                        </tr>

                                        <tr>
                                            <td>수기배송</td>
                                            <td>1건당 2,500원</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.수기배송))}</td>
                                        </tr>
                                        {/*
                                        <tr>
                                            <td>백신 휴무 지원금</td>
                                            <td>백신 접종시 기본급 1일 지급</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.백신휴무지원금))}</td>
                                        </tr>
                                        */}
                                        <tr>
                                            <td>특수권역 인센티브</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.특수권역인센티브))}</td>
                                        </tr>
                                        
                                        <tr>
                                            <td>패널티 공제</td>
                                            <td>상품누락, 오배송으로 인한 패널티(이마트 공제기준)</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.공제비용))}</td>
                                        </tr>

                                        <tr style={{"textAlign":"center", "textAlign": "center", "backgroundColor": "yellow", "fontWeight": "bold", "fontSize": "14px"}}>
                                            <td colSpan="3">운송료 지급 합계(부가세 포함)</td>
                                            <td>{commaValue(Math.floor(data.기사지급액지입료공제후*1.1))}</td>
                                        </tr>
                                        
                                        <tr>
                                            <td>관리비 및 부가세(지입료)</td>
                                            <td>대한통운 지입료</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.관리비및부가세))}</td>
                                        </tr>

                                        <tr>
                                            <td>회차 및 갱신 보험</td>
                                            <td rowSpan={6} style={{"verticalAlign":"middle"}}>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.회차및갱신보험))}</td>
                                        </tr>

                                        <tr>
                                            <td>환경 부담금</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.환경부담금))}</td>
                                        </tr>

                                        <tr>
                                            <td>협회비</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.협회비))}</td>
                                        </tr>

                                        <tr>
                                            <td>자동차세</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.자동차세))}</td>
                                        </tr>

                                        <tr>
                                            <td>과태료공제</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.과태료공제))}</td>
                                        </tr>

                                        <tr>
                                            <td>기타분납금</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.기타분납금))}</td>
                                        </tr>

                                        <tr style={{"textAlign":"center", "textAlign": "center", "backgroundColor": "yellow", "fontWeight": "bold", "fontSize": "14px"}}>
                                            <td colSpan="3">공제 후 지급 합계</td>
                                            <td>{commaValue(Math.floor(data.기사급여이체))}</td>
                                        </tr>
                                    </tbody>                 
                                :data.점포명 === "자양점"?
                                    <tbody>
                                        <tr>
                                            <td>기본 도급</td>
                                            <td>'운행일수 1일당 134,650원(13% 수수료 공제)</td>
                                            <td>{commaValue(nvl(data.도급일))} 일</td>
                                            <td>{commaValue(nvl(data.기본도급료공제대상))}</td>
                                        </tr>

                                        <tr>
                                            <td>건수인센티브</td>
                                            <td>
                                                일 건수(30건 초과 건당 2,700원 / 35건 초과 건당<br/>
                                                3,000원 / 40건 초과 건당 3,500원 / 45건 초과 건당 4,000원)<br/>
                                            </td>
                                            <td>{commaValue(nvl(data.총배송처수))} 건</td>
                                            <td>{commaValue(nvl(data.건수인센티브))}</td>
                                        </tr>

                                        <tr>
                                            <td>야간 인센티브</td>
                                            <td>1일당 3,850원</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.야간인센티브))}</td>
                                        </tr>
                                        
                                        <tr>
                                            <td>수기배송</td>
                                            <td>1건당 2,500원/건수 배분 1건당 5,000원</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.수기배송))}</td>
                                        </tr>

                                        <tr>
                                            <td>온도관제미준수</td>
                                            <td>온도관제 미준수시 1日 기본도급료 1%공제</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.온도관제미설치))}</td>
                                        </tr>

                                        <tr>
                                            <td>기타수당</td>
                                            <td>유니폼,차량청결,기타수당,인센티브(배송) 합</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.인센티브합))}</td>
                                        </tr>
                                        {/*
                                        <tr>
                                            <td>백신 휴무 지원금</td>
                                            <td>백신 접종시 기본급 1일 지급</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.백신휴무지원금))}</td>
                                        </tr>
                                        */}
                                        <tr>
                                            <td>경조사 지원금</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.경조사지원금))}</td>
                                        </tr>

                                        <tr>
                                            <td>패널티 공제</td>
                                            <td>상품누락, 오배송으로 인한 패널티(이마트 공제기준)</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.공제비용))}</td>
                                        </tr>

                                        <tr style={{"textAlign":"center", "textAlign": "center", "backgroundColor": "yellow", "fontWeight": "bold", "fontSize": "14px"}}>
                                            <td colSpan="3">
                                                총합(부가세 포함) 
                                            </td>
                                            <td>{commaValue(Math.floor(data.기사지급액지입료공제후*1.1))}</td>
                                        </tr>
                                        <tr>
                                            <td>관리비 및 부가세(지입료)</td>
                                            <td>조은운수 지입료</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.관리비및부가세))}</td>
                                        </tr>

                                        <tr>
                                            <td>회차 및 갱신보험</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.회차및갱신보험))}</td>
                                        </tr>

                                        <tr>
                                            <td>환경부담금</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.환경부담금))}</td>
                                        </tr>

                                        <tr>
                                            <td>협회비</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.협회비))}</td>
                                        </tr>
                                        <tr>
                                            <td>산재/고용보험</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.산재고용보험))}</td>
                                        </tr>
                                        <tr>
                                            <td>과태료공제</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.과태료공제))}</td>
                                        </tr>
                                        <tr>
                                            <td>전월미수 및 기타비용</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.전월미수및기타비용))}</td>
                                        </tr>
                                        <tr>
                                            <td>기타분납금</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.기타분납금))}</td>
                                        </tr>
                                        <tr style={{"textAlign":"center", "textAlign": "center", "backgroundColor": "yellow", "fontWeight": "bold", "fontSize": "14px"}}>
                                            <td colSpan="3">공제 후 운송료 지급 합계</td>
                                            <td>{commaValue(nvl(data.기사급여이체))}</td>
                                        </tr>
                                    </tbody>
                                :data.점포명 === "하월곡점"?
                                    <tbody>
                                        <tr>
                                            <td>기본 도급</td>
                                            <td>'운행일수 1일당 134,650원(13% 수수료 공제)</td>
                                            <td>{commaValue(nvl(data.도급일))} 일</td>
                                            <td>{commaValue(nvl(data.기본도급료공제대상))}</td>
                                        </tr>

                                        <tr>
                                            <td>건수인센티브</td>
                                            <td>
                                                일 건수(30건 초과 건당 2,700원 / 35건 초과 건당<br/>
                                                3,000원 / 40건 초과 건당 3,500원 / 45건 초과 건당 4,000원)<br/>
                                            </td>
                                            <td>{commaValue(nvl(data.총배송처수))} 건</td>
                                            <td>{commaValue(nvl(data.건수인센티브))}</td>
                                        </tr>

                                        <tr>
                                            <td>야간 인센티브</td>
                                            <td>1일당 3,850원</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.야간인센티브))}</td>
                                        </tr>
                                        
                                        <tr>
                                            <td>수기배송</td>
                                            <td>1건당 2,500원/건수 배분 1건당 5,000원</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.수기배송))}</td>
                                        </tr>

                                        <tr>
                                            <td>온도관제미준수</td>
                                            <td>온도관제 미준수시 1日 기본도급료 1%공제</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.온도관제미설치))}</td>
                                        </tr>

                                        <tr>
                                            <td>기타수당</td>
                                            <td>유니폼,차량청결,기타수당,인센티브(배송) 합</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.인센티브합))}</td>
                                        </tr>
                                        {/*
                                        <tr>
                                            <td>백신 휴무 지원금</td>
                                            <td>백신 접종시 기본급 1일 지급</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.백신휴무지원금))}</td>
                                        </tr>
                                        */}
                                        <tr>
                                            <td>경조사 지원금</td>
                                            <td></td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.경조사지원금))}</td>
                                        </tr>

                                        <tr>
                                            <td>패널티 공제</td>
                                            <td>상품누락, 오배송으로 인한 패널티(이마트 공제기준)</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.공제비용))}</td>
                                        </tr>

                                        <tr style={{"textAlign":"center", "textAlign": "center", "backgroundColor": "yellow", "fontWeight": "bold", "fontSize": "14px"}}>
                                            <td colSpan="3">
                                                총합(부가세 포함) 
                                            </td>
                                            <td>{commaValue(Math.floor(data.기사지급액지입료공제후*1.1))}</td>
                                        </tr>
                                        <tr>
                                            <td>관리비 및 부가세(지입료)</td>
                                            <td>조은운수 지입료</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.관리비및부가세))}</td>
                                        </tr>

                                        <tr>
                                            <td>회차 및 갱신보험</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.회차및갱신보험))}</td>
                                        </tr>

                                        <tr>
                                            <td>환경부담금</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.환경부담금))}</td>
                                        </tr>

                                        <tr>
                                            <td>협회비</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.협회비))}</td>
                                        </tr>
                                        <tr>
                                            <td>산재/고용보험</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.산재고용보험))}</td>
                                        </tr>
                                        <tr>
                                            <td>과태료공제</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.과태료공제))}</td>
                                        </tr>
                                        <tr>
                                            <td>전월미수 및 기타비용</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.전월미수및기타비용))}</td>
                                        </tr>
                                        <tr>
                                            <td>기타분납금</td>
                                            <td>부가세 제외 항목</td>
                                            <td></td>
                                            <td>{commaValue(nvl(data.기타분납금))}</td>
                                        </tr>
                                        <tr style={{"textAlign":"center", "textAlign": "center", "backgroundColor": "yellow", "fontWeight": "bold", "fontSize": "14px"}}>
                                            <td colSpan="3">공제 후 운송료 지급 합계</td>
                                            <td>{commaValue(nvl(data.기사급여이체))}</td>
                                        </tr>
                                    </tbody>
                                :null}
                                
                        </Table>
                        
                    </div>
                    <div>
                        <Button variant="primary" onClick={handleCapture1} style={{"position":"fixed", "bottom":"55px", "right":"10px","zIndex":"999999"}}>저장</Button>
                    </div>
                </div>
                ,
                <div style={{"marginBottom":"50px"}}>
                    {loading2 && <Loading />}
                    <Table cellSpacing="0" style={{"borderCollapse":"collapse", "width":"741px", "lineHeight":"1", "border":"1px solid blue"}} ref={captureRef2} id="tabTaxSpec">
                        <tbody>
                            <tr>
                                <td colSpan="15" rowSpan="2" style={{"height":"52px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "width":"375px"}}><strong>세 금 계 산 서</strong></td>
                                <td rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "width":"23px"}}><strong>(</strong></td>
                                <td colSpan="5" style={{"verticalAlign":"middle", "whiteSpace":"nowrap", "width":"106px", "borderBottom":"none"}}><strong>공급자</strong></td>
                                <td rowSpan="2" style={{"verticalAlign":"middle", "whiteSpace":"nowrap", "width":"22px"}}><strong>)</strong></td>
                                <td colSpan="4" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "width":"88px", "borderLeft":"1px solid blue", "borderRight":"1px solid blue"}}>책&nbsp;&nbsp; 번&nbsp;&nbsp; 호</td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "width":"22px"}}>　</td>
                                <td style={{"textAlign":"right","verticalAlign":"middle", "whiteSpace":"nowrap", "width":"22px"}}>　</td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "width":"22px", "borderRight":"1px solid blue"}}>권</td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "width":"22px"}}>　</td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "width":"22px"}}>　</td>
                                <td style={{"textAlign":"right", "verticalAlign":"middle", "whiteSpace":"nowrap", "width":"22px"}}>호</td>
                            </tr>
                            <tr>
                                <td colSpan="5" style={{"height":"26px", "verticalAlign":"middle", "whiteSpace":"nowrap"}}><strong>보&nbsp; 관&nbsp; 용</strong></td>
                                <td colSpan="4" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderLeft":"1px solid blue", "borderRight":"1px solid blue"}}>일 련 번 호</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>　</td>
                            </tr>
                            <tr>
                                <td rowSpan="8" style={{"height":"144px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>공 급 자</td>
                                <td colSpan="3" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>등록번호</td>
                                <td colSpan="12" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}><strong>{data.등록번호}</strong></td>
                                <td rowSpan="8" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderLeft":"1px solid blue", "borderRight":"1px solid blue"}}>공급받는자</td>
                                <td colSpan="3" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>등록번호</td>
                                <td colSpan="12" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}><strong>183-88-02505</strong></td>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                                <td colSpan="3" style={{"height":"18px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue", "borderBottom":"none"}}>상&nbsp;&nbsp;&nbsp;&nbsp; 호</td>
                                <td colSpan="6" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>{data.상호}</td>
                                <td rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderLeft":"1px solid blue", "borderRight":"1px solid blue"}}>성명</td>
                                <td colSpan="4" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>{data.성명}</td>
                                <td rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderLeft":"1px solid blue"}}>인</td>
                                <td colSpan="3" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue", "borderBottom":"none"}}>상&nbsp;&nbsp;&nbsp;&nbsp; 호</td>
                                <td colSpan="6" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>주식회사 신아로지스</td>
                                <td rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderLeft":"1px solid blue", "borderRight":"1px solid blue"}}>성명</td>
                                <td colSpan="4" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>박경열</td>
                                <td rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderLeft":"1px solid blue"}}>인</td>
                            </tr>
                            <tr>
                                <td colSpan="3" style={{"height":"18px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>(법인명)</td>
                                <td colSpan="3" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>(법인명)</td>
                            </tr>
                            <tr>
                                <td colSpan="3" style={{"height":"18px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue", "borderBottom":"none"}}>사 업 장</td>
                                <td colSpan="12" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"normal", "width":"314px"}}>{data.사업장주소}</td>
                                <td colSpan="3" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue", "borderBottom":"none"}}>사 업 장</td>
                                <td colSpan="12" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"normal", "width":"264px"}}>경기도 김포시 고촌읍<br />
                                신기신동로 44, 2층</td>
                            </tr>
                            <tr>
                                <td colSpan="3" style={{"height":"18px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>주&nbsp;&nbsp;&nbsp;&nbsp; 소</td>
                                <td colSpan="3" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>주&nbsp;&nbsp;&nbsp;&nbsp; 소</td>
                            </tr>
                            <tr>
                                <td colSpan="3" rowSpan="2" style={{"height":"36px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>업&nbsp;&nbsp;&nbsp;&nbsp; 태</td>
                                <td colSpan="6" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>{data.업태}</td>
                                <td rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderLeft":"1px solid blue", "borderRight":"1px solid blue"}}>종목</td>
                                <td colSpan="5" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"normal", "width":"108px"}}>{data.종목}</td>
                                <td colSpan="3" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>업&nbsp;&nbsp;&nbsp;&nbsp; 태</td>
                                <td colSpan="6" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>운수업</td>
                                <td rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderLeft":"1px solid blue", "borderRight":"1px solid blue"}}>종목</td>
                                <td colSpan="5" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>화물</td>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                                <td colSpan="4" style={{"height":"18px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>작&nbsp;&nbsp;&nbsp;&nbsp; 성</td>
                                <td colSpan="13" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>공&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 급&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 가&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 액</td>
                                <td colSpan="10" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>세&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 액</td>
                                <td colSpan="5" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>비&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 고</td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{"height":"18px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>년</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>월</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>일</td>
                                <td colSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>공란수</td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>백</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>십</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>억</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>천</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>백</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>십</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>만</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>천</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>백</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>십</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>일</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>십</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>억</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>천</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>백</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>십</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>만</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>천</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>백</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>십</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>일</td>
                                <td colSpan="5" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderLeft":"1px solid blue"}}>　</td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{"height":"20px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>{subStringMonthDay("year", data.작성일)}</td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>{subStringMonthDay("month", data.작성일)}</td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>{subStringMonthDay("day", data.작성일)}</td>
                                <td colSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>{data.공란수}</td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(11, data.공급가액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(10, data.공급가액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(9, data.공급가액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(8, data.공급가액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(7, data.공급가액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(6, data.공급가액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(5, data.공급가액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(4, data.공급가액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(3, data.공급가액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(2, data.공급가액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(1, data.공급가액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(10, data.세액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(9, data.세액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(8, data.세액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(7, data.세액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(6, data.세액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(5, data.세액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(4, data.세액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(3, data.세액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}><strong>{subStringVal(2, data.세액)}</strong></td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}><strong>{subStringVal(1, data.세액)}</strong></td>
                            </tr>
                            <tr>
                                <td style={{"height":"18px", "textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>월</td>
                                <td style={{"textAlign":"left", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>일</td>
                                <td colSpan="6" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>품&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 목</td>
                                <td colSpan="4" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>규격</td>
                                <td colSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>수량</td>
                                <td colSpan="5" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>단&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 가</td>
                                <td colSpan="6" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>공&nbsp; 급&nbsp; 가&nbsp; 액</td>
                                <td colSpan="5" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>세&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 액</td>
                                <td colSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>비고</td>
                            </tr>
                            <tr>
                                <td style={{"height":"26px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>{subStringMonthDay("month", data.월일)}</td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>{subStringMonthDay("day", data.월일)}</td>
                                <td colSpan="6" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>{data.품목}</td>
                                <td colSpan="4" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>1</td>
                                <td colSpan="5" style={{"verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>{commaValue(nvl(data.단가))}</td>
                                <td colSpan="6" style={{"verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>{commaValue(nvl(data.공급가액))}</td>
                                <td colSpan="5" style={{"textAlign":"right", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {commaValue(nvl(data.세액))}</td>
                                <td colSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>　</td>
                            </tr>
                            <tr>
                                <td style={{"height":"26px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="6" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="4" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="5" style={{"verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="6" style={{"verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="5" style={{"verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>　</td>
                            </tr>
                            <tr>
                                <td style={{"height":"26px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="6" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="4" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="5" style={{"verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="6" style={{"verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="5" style={{"verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>　</td>
                            </tr>
                            <tr>
                                <td style={{"height":"26px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="6" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="4" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="5" style={{"verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="6" style={{"verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="5" style={{"verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>　</td>
                            </tr>
                            <tr>
                                <td colSpan="5" style={{"height":"18px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>합계금액</td>
                                <td colSpan="5" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>현&nbsp; 금</td>
                                <td colSpan="5" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>수&nbsp; 표</td>
                                <td colSpan="5" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>어&nbsp; 음</td>
                                <td colSpan="5" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>외상미수금</td>
                                <td colSpan="4" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderLeft":"1px solid blue", "borderRight":"1px solid blue"}}>이 금액을&nbsp;</td>
                                <td colSpan="2" rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"normal", "width":"44px"}}><strong>영수<br />청구</strong></td>
                                <td rowSpan="2" style={{"textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>함</td>
                            </tr>
                            <tr>
                                <td colSpan="5" style={{"height":"26px", "textAlign":"center", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>{commaValue(Number(nvl(data.공급가액))+Number(nvl(data.세액)))}</td>
                                <td colSpan="5" style={{"textAlign":"right", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="5" style={{"textAlign":"right", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="5" style={{"textAlign":"right", "verticalAlign":"middle", "whiteSpace":"nowrap", "borderRight":"1px solid blue"}}>　</td>
                                <td colSpan="5" style={{"textAlign":"right", "verticalAlign":"middle", "whiteSpace":"nowrap"}}>　</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div>
                        <Button variant="primary" onClick={handleCapture2} style={{"position":"fixed", "bottom":"55px", "right":"10px","zIndex":"999999"}}>저장</Button>
                    </div>
                    
                </div>
            ][tab]
        }
    </div>
}
export default PaySpec;