import axios from 'axios';
import TokenService from './TokenService';

const API_URL = process.env.REACT_APP_USER_API_URL+"payspec/";

export default class PaySpecService {

    static async paySpecList(data){
      let token = TokenService.get();

      let result = await axios.post(API_URL+"list", data, {
        headers: {
          "Authorization" : `Bearer ${token}`,
        }
      });
        return result.data;
    }

    static async paySpecDetail(data){
      let token = TokenService.get();

      let result = await axios.post(API_URL+"detail", data, {
        headers: {
          "Authorization" : `Bearer ${token}`,
        }
      });
      return result.data.data;
    }

    static async add(data){
      let token = TokenService.get();

      let result = await axios.post(API_URL+"add", data, {
        headers: {
          "Authorization" : `Bearer ${token}`,
        }
      });
      return result.data;
    }
}