import React, {useEffect, useState} from 'react';
import { useLocation } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { signUpRequestAction, userInitAction } from '../reducers/user';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import logo from '../image/sinalogo.png';

const SignUp = () => {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [pw1, setPw1] = useState('');
    const [pw2, setPw2] = useState('');
    const [pwFlag, setPwFlag] = useState(false);
    const [txtState, setTxtState] = useState();
    
    const [cookies, setCookie, removeCookie] = useCookies(['rememberId']);
    const expires = new Date();

    const error = useSelector(
        (state) => state.user.signUpError,
    );

    const success = useSelector(
        (state) => state.user.signUpDone,
    );

    useEffect(()=>{
        dispatch(userInitAction());
    },[]);

    useEffect(()=>{
        if(error === null)return;
        console.log(error);
    }, [error]);

    useEffect(()=>{
        if(success){
            alert('비밀번호 설정이 완료 되었습니다.\n로그인 페이지로 이동합니다.');
            setCookie('rememberId', state, {maxAge: expires.getFullYear()+1});
            navigate("/signin");
        }
    },[success]);

    const onSubmitFrom =(e)=>{
        e.preventDefault();
        if(pwFlag){
            dispatch(signUpRequestAction({id:state, password:pw1}));
        }
    };

    return (
        <>
            <div>
                <div style={{"paddingTop":"100px", "paddingBottom":"40px","textAlign":"center"}}>
                    <img src={logo} style={{"maxWidth": "90%", "height": "auto"}}/>
                </div>
                <Container className="panel">
                    <Form onSubmit={onSubmitFrom}>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Col sm>
                                <Form.Control type="input" value={state} readOnly/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Col sm>
                                <Form.Control type="password" onChange={(e)=>{
                                    setPw1(e.target.value);
                                    if(e.target.value != pw2){
                                        setTxtState("비밀번호가 일지하지 않습니다.");
                                    }else{
                                        setTxtState("");
                                        setPwFlag(true);
                                    }
                                }} placeholder="비밀번호" required/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Col sm>
                                <Form.Control type="password" onChange={(e)=>{
                                    setPw2(e.target.value);
                                    if(pw1 != e.target.value){
                                        setTxtState("비밀번호가 일지하지 않습니다.");
                                    }else{
                                        setTxtState("");
                                        setPwFlag(true);
                                    }
                                }} placeholder="비밀번호" required/>
                            </Col>
                        </Form.Group>
                        <br/>
                        <p>{txtState}</p>
                        <div className="d-grid gap-1">
                            <Button variant="secondary" type="submit" >
                                회원가입
                            </Button>
                        </div>
                    </Form>
                </Container>
            </div>
        </>
    )
}

export default SignUp;