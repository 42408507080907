import React from 'react';
import { Nav } from 'react-bootstrap';
import './BottomNav.css';

const NavBarBottomComp = () => {

    let date = new Date();
    let this_month = date.getMonth() + 1;
    let this_year = date.getFullYear();
    if(this_month < 10){
        this_month = "0"+this_month;
    }
    let paySpecUrl = "/payspec/"+this_year+this_month;

    return (
        <>
            <nav className="wrapper">
                <div><Nav.Link href="/">Home</Nav.Link></div>
                <div><Nav.Link href={paySpecUrl}>운송료내역서</Nav.Link></div>
                <div><Nav.Link href="/notice/list">공지사항</Nav.Link></div>
            </nav>
        </>
    )
}

export default NavBarBottomComp;