import { useEffect, useState } from 'react';
import {Nav, Container, Navbar, NavDropdown, Offcanvas, Button, Form} from 'react-bootstrap';
import TokenService from '../service/TokenService';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router';
import title from '../image/sinatitle.png';

const NavBarComp = () => {
    const navigate = useNavigate();
    
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [auth, setAuth] = useState("");

    let date = new Date();
    let this_month = date.getMonth() + 1;
    let this_year = date.getFullYear();
    if(this_month < 10){
        this_month = "0"+this_month;
    }
    let paySpecUrl = "/payspec/"+this_year+this_month;

    useEffect(() => {
        let token = TokenService.get();
        
        if(token != null && token != undefined){
            setId(jwt_decode(token).user_id);
            setName(jwt_decode(token).user_name);
            setAuth(jwt_decode(token).user_auth);
        }
    },[]);

    return (
        <>
            <Navbar key="md" bg="light" expand="md" className="mb-3" style={{"position": "fixed", "top": "0", "width": "100%", "left": "0", "right": "0", "overflow":"inherit", "borderTop":"0px", "zIndex":"9999"}}>
                <Container fluid>
                    <Navbar.Brand href="/" style={{"width":"160px"}}><img src={title} style={{"maxWidth": "100%", "height": "auto"}}/></Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
                    <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-md`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                    placement="end"
                    >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Nav.Link href="#">{name}</Nav.Link>
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href={paySpecUrl}>운송료내역서</Nav.Link>
                            <Nav.Link href="/notice/list">공지사항</Nav.Link>
                            
                            {auth === "A"?<NavDropdown title="관리" id={`offcanvasNavbarDropdown-expand-md`}>
                                <NavDropdown.Item href="/manage/user/list">사용자관리</NavDropdown.Item>
                                <NavDropdown.Item href="/manage/payspec">급여관리</NavDropdown.Item>
                                <NavDropdown.Item href="/manage/notice/list">공지사항관리</NavDropdown.Item>
                            </NavDropdown>:null}
                            {id?<Nav.Link href="/signout" style={{"color":"red", "fontWeight":"bold"}}>로그아웃</Nav.Link>:<Nav.Link href="/signin">로그인</Nav.Link>}
                        </Nav>
                    </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </>
    )
}

export default NavBarComp;