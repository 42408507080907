export const initialState = {
    loading : false,
    logInDone : false, 
    logInError : null,
    signOutDone : false,
    signOutError : false,
    signUpDone : false,
    signUpError : null,
    userDone : false,
    userError : null,
    userData : null,
    userActDone : false,
    userActError : null,
    token : null
};

export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';

export const SIGN_OUT_REQUEST = "SIGN_OUT_REQUEST";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";

export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";

export const USER_STATE_MOD_REQUEST = "USER_STATE_MOD_REQUEST";
export const USER_STATE_MOD_SUCCESS = "USER_STATE_MOD_SUCCESS";
export const USER_STATE_MOD_FAILURE = "USER_STATE_MOD_FAILURE";

export const USER_AUTH_MOD_REQUEST = "USER_AUTH_MOD_REQUEST";
export const USER_AUTH_MOD_SUCCESS = "USER_AUTH_MOD_SUCCESS";
export const USER_AUTH_MOD_FAILURE = "USER_AUTH_MOD_FAILURE";

export const USER_DEL_REQUEST = "USER_DEL_REQUEST";
export const USER_DEL_SUCCESS = "USER_DEL_SUCCESS";
export const USER_DEL_FAILURE = "USER_DEL_FAILURE";

export const USER_PWD_INIT_REQUEST = "USER_PWD_INIT_REQUEST";
export const USER_PWD_INIT_SUCCESS = "USER_PWD_INIT_SUCCESS";
export const USER_PWD_INIT_FAILURE = "USER_PWD_INIT_FAILURE";

export const USER_INIT_STATE = "USER_INIT_STATE";

export const loginRequestAction = (userData) => {
    console.log('reducer / loginRequestAction ');
    return {
        type: LOG_IN_REQUEST,
        data: userData
    }
};

export const signUpRequestAction = (userData) =>{
    console.log('reducer / signUpRequestAction ');
    return {
        type: SIGN_UP_REQUEST,
        data: userData
    }
};

export const signOutRequestAction = () => {
    console.log('reducer / signOutRequestAction');
    return {
        type: SIGN_OUT_REQUEST,
    }
}

export const userListRequestAction = (userData) => {
    console.log('reducer / userListRequestAction');

    return {
        type : USER_LIST_REQUEST,
        data : userData
    }
};

export const userStateModRequestAction = (userData) => {
    console.log('reducer / userStateModRequestAction');
    
    return {
        type : USER_STATE_MOD_REQUEST,
        data : userData
    }
}

export const userAuthModRequestAction = (userData) => {
    console.log('reducer / userAuthModRequestAction');

    return {
        type : USER_AUTH_MOD_REQUEST,
        data : userData
    }
}

export const userDelRequestAction = (userData) => {
    console.log('reducer / userDelRequestAction');

    return {
        type : USER_DEL_REQUEST,
        data : userData
    }
}

export const userPwdInintRequestAction = (userData) => {
    console.log('reducer / userPwdInitRequestAction');

    return {
        type : USER_PWD_INIT_REQUEST,
        data : userData
    }
}

export const userInitAction = () => {
    console.log('reducer / user state initAction ')
    return {
        type : USER_INIT_STATE,
    }
}

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case LOG_IN_REQUEST:{
            console.log('reducer / 로그인 요청');
            return {
                ...state,
                loading : true,
                logInDone : false,
                logInError : null,
            };
        }
        case LOG_IN_SUCCESS:{
            console.log('reducer / 로그인 완료');
            return {
                ...state,
                loading : false,
                logInDone : true,
                token : action.data
            };
        }
        case LOG_IN_FAILURE:{
            console.log('reducer / 로그인 실패');
            return {
                loading : false,
                logInError : action.error
            };
        }

        case SIGN_OUT_REQUEST:{
            console.log('reducer / 로그아웃 요청');
            return {
                ...state,
                loading : true,
                signOutDone : false,
                signOutError : null,
            };
        }
        case SIGN_OUT_SUCCESS:{
            console.log('reducer / 로그아웃 완료');
            return {
                ...state,
                loading : false,
                signOutDone : true
            };
        }
        case SIGN_OUT_FAILURE:{
            console.log('reducer / 로그아웃 실패');
            return {
                loading : false,
                signOutDone : action.error
            };
        }

        case SIGN_UP_REQUEST:{
            console.log('reducer / 회원가입 요청');
            return {
                ...state,
                loading : true,
                signUpDone : false,
                signUpError : null,
            };
        }
        case SIGN_UP_SUCCESS:{
            console.log('reducer / 회원가입 완료');
            return {
                ...state,
                loading : false,
                signUpDone : true,
                logInError : null,
                userData : action.data
            };
        }
        case SIGN_UP_FAILURE:{
            console.log('reducer / 회원가입 실패');
            return {
                loading : false,
                signUpError : action.error
            };
        }
        case USER_LIST_REQUEST:{
            console.log('reducer / 유저 목록 조회 요청');
            return {
                ...state,
                loading : true,
                userDone : false,
                userError : null,
            };
        }
        case USER_LIST_SUCCESS:{
            console.log('reducer / 유저목록 조회 완료');
            return {
                ...state,
                loading : false,
                userDone : true,
                userData : action.data
            };
        }
        case USER_LIST_FAILURE:{
            console.log('reducer / 유저 목록 조회 실패');
            return {
                loading : false,
                userError : action.error
            };
        }
        case USER_STATE_MOD_REQUEST:{
            console.log('reducer / 유저 상태 수정 요청');
            return {
                ...state,
                loading : true,
                userActDone : false,
                userActError : null,
            };
        }
        case USER_STATE_MOD_SUCCESS:{
            console.log('reducer / 유저 상태 수정 완료');
            return {
                ...state,
                loading : false,
                userActDone : true,
                userActData : action.data
            };
        }
        case USER_STATE_MOD_FAILURE:{
            console.log('reducer / 유저 상태 수정 실패');
            return {
                loading : false,
                userActError : action.error
            };
        }
        case USER_AUTH_MOD_REQUEST:{
            console.log('reducer / 유저 권한 수정 요청');
            return {
                ...state,
                loading : true,
                userActDone : false,
                userActError : null,
            };
        }
        case USER_AUTH_MOD_SUCCESS:{
            console.log('reducer / 유저 권한 수정 완료');
            return {
                ...state,
                loading : false,
                userActDone : true,
                userActData : action.data
            };
        }
        case USER_AUTH_MOD_FAILURE:{
            console.log('reducer / 유저 권한 수정 실패');
            return {
                loading : false,
                userActError : action.error
            };
        }
        case USER_DEL_REQUEST:{
            console.log('reducer / 유저 삭제 요청');
            return {
                ...state,
                loading : true,
                userActDone : false,
                userActError : null,
            };
        }
        case USER_DEL_SUCCESS:{
            console.log('reducer / 유저 삭제 완료');
            return {
                ...state,
                loading : false,
                userActDone : true,
                userActData : action.data
            };
        }
        case USER_DEL_FAILURE:{
            console.log('reducer / 유저 삭제 실패');
            return {
                loading : false,
                userActError : action.error
            };
        }
        
        case USER_PWD_INIT_REQUEST:{
            console.log('reducer / 유저 비밀번호 초기화 요청');
            return {
                ...state,
                loading : true,
                userActDone : false,
                userActError : null,
            };
        }
        case USER_PWD_INIT_SUCCESS:{
            console.log('reducer / 유저 비밀번호 초기화 완료');
            return {
                ...state,
                loading : false,
                userActDone : true,
                userActData : action.data
            };
        }
        case USER_PWD_INIT_FAILURE:{
            console.log('reducer / 유저 비밀번호 초기화 실패');
            return {
                loading : false,
                userActError : action.error
            };
        }

        case USER_INIT_STATE:{
            console.log('reducer / 유저 스테이트 초기화');
            return {
                logInError : null,
                signOutError : false,
                signUpError : null,
                userError : null,
                userActError : null,
            }
        }
        default: {
            return{
                ...state,
            }
        }    
    }
};

export default reducer;